.footer {
    background: rgb(255,149,34);
    background: linear-gradient(90deg, rgba(255,149,34,1) 23%, rgba(255,149,34,1) 32%, rgba(248,85,107,1) 86%);
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 5%;
    .icon-contact {
        width: 40px;
    }
}

.footer-bottom-container {
    padding-left: 6rem;
    padding-right: 6rem;
    display: flex;
    justify-content:center;
}